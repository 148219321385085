:root {
  /* font-size: 60%; */
  font-family: "SegoeUI";
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: Sul Sans,sans-serif; */
}

html,
body,
#root {
}

body,
input,
button,
textarea,
td {
  font-weight: 500;
  font-size: 1.6rem;
}

/* ScrollBar Global Pages*/

::-webkit-scrollbar-track {
  border-radius: 5px;
}

::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar {
  background: #121212;
}

::-webkit-scrollbar-thumb {
  background: #343946;
}

@media (min-width: 700px) {
  :root {
    font-size: 62.5%;
  }
}


h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
}

a {
  text-decoration: none;
  cursor: pointer;
}