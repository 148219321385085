.error-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  min-height: 100% - 10px;
  display: flex;
  flex-direction: column;

  margin: 0;
  padding: 0;
  background: #010214;
}

.error403-container {
  border-top: 10px solid #e91e63;
}

.error403-container h1 {
  color: #e91e63;
}

.error404-container {
  border-top: 10px solid blue;
}

.error404-container h1 {
  color: blue;
}

.error500-container {
  border-top: 10px solid green;
}

.error500-container h1 {
  color: green;
}

.exception-body-container {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.exception-body-image {
  display: flex;
  width: 40%;
  align-items: center;
  justify-content: center;
}

.exception-body-image img {
  height: 80vh;
  width: 80vh;
}

.exception-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding-left: 100px;
  background-color: #252937;
}

.exception-container img {
  display: inline-block;
  width: 60px;
  height: 60px;
  margin: 100px 0;
}

.exception-container h1 {
  font-size: 80px;
  font-weight: normal;
  margin: 0;
}

.exception-container p {
  color: #696e7d;
  margin-top: 0;
  margin-bottom: 40px;
}
